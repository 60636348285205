// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

// * -------------------------------- COMPONENTS --------------------------------------
import TopBar from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/TopBar'
import { RouteProps } from '@mv-submodules/inplant-components-fe/ui/components/MVLayout/SideBar/SideBar'
import { Badge } from '@mv-submodules/inplant-components-fe/ui/components/MVBadges/Badge'

// * -------------------------------- MODULE --------------------------------------
import { logoutUser, User } from '@mv-submodules/inplant-core-fe/auth'
import { ButtonVariants } from '@mv-submodules/inplant-components-fe/ui/components/MVButtons/types'
import { themeList, Theme, ThemeImpl } from '@mv-submodules/inplant-core-fe/types/theme'
import { changeTheme, changeAssets } from '@mv-submodules/inplant-core-fe/redux/actions/branding'
import DropdownButton, {
  DropdownActions,
} from '@mv-submodules/inplant-components-fe/ui/components/MVButtons/DropdownButton'
import { WithTranslation, withTranslation } from 'react-i18next'
import Button from '@mv-submodules/inplant-components-fe/ui/components/MVButtons/Button'
import { uniqElements } from '@mv-submodules/inplant-components-fe/mvfunctions/helpers/arrayHelper'
import { API_BASE_URL } from '@mv-submodules/inplant-core-fe/types'
import i18next from 'i18next'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'

export interface TopBarStateProps {
  loginSuccess: boolean
  mobile: boolean
  user: User | null
  defaultTheme: ThemeImpl
}

export interface TopBarOwnProps {
  logo: string
  routes: RouteProps[]
}

interface DispatchProps {
  logout: () => void
  changeTheme: (clientSlug: string) => Function
  changeAssets: (clientSlug: string) => Function
}

export type TopBarProps = TopBarStateProps & TopBarOwnProps & DispatchProps & WithTranslation

export class TopBarCore extends React.Component<TopBarProps> {
  private BASE_TRAD = 'topbar'

  private getUserProfileLinks(routeProps: RouteProps[]): DropdownActions {
    const { user } = this.props

    function getUserProfileRoutes(routes: RouteProps[]): RouteProps[] {
      return routes
        .map((route: RouteProps) => {
          if (route.children) {
            return getUserProfileRoutes(route.children)
          }
          if (
            (route.aclActionKey &&
              user &&
              !user.forbiddenActions.includes(route.aclActionKey) &&
              route.visibleInUserProfile) ||
            (!route.aclActionKey && route.visibleInUserProfile)
          ) {
            return [route]
          }
          return []
        })
        .reduce((acc, cur) => acc.concat(cur), [])
    }

    return getUserProfileRoutes(routeProps).map(link => ({
      kind: 'link-action',
      label: this.props.t(link.i18nkey),
      to: link.path,
    }))
  }

  private renderThemeDropdownButton = () => {
    const nextRelease = true
    if (nextRelease) {
      return
    }
    const themes = uniqElements(themeList, 'themeName')

    if (themes.length > 1) {
      return (
        <DropdownButton
          actions={themes
            .sort((a, b) => a.themeName.localeCompare(b.themeName))
            .map(theme => {
              return {
                kind: 'action',
                label: theme.themeName,
                onClick: () => {
                  this.props.changeTheme(theme.themeName)
                  this.props.changeAssets(theme.themeName)
                },
              }
            })}
          variant={ButtonVariants.outline}
          label={this.props.defaultTheme.themeName}
          semantic="primary"
        />
      )
    }
    return null
  }

  private renderChangeLanguageButton = () => {
    const storedLanguage = Object.keys((i18next as any).store.data)
    return (
      <DropdownButton
        actions={storedLanguage.map(language => ({
          label: language.toUpperCase(),
          kind: 'action',
          onClick: () => {
            mvDate.setCurrentLanguage(i18next.language)
            i18next.changeLanguage(language)
            location.reload()
          },
        }))}
        semantic="brand"
        label={i18next.language.toUpperCase()}
        icon={'globe'}
      />
    )
  }

  private renderLightDarkButton = () => {
    const nextRelease = true
    if (nextRelease) {
      return
    }

    const variantThemeResult = themeList.find(
      theme =>
        theme.themeName === this.props.defaultTheme.themeName && theme.themeType !== this.props.defaultTheme.themeType
    )
    if (variantThemeResult) {
      const variantThemeToApply: Theme = {
        themeFile: variantThemeResult.themeFile,
        themeName: variantThemeResult.themeName,
        themeType: variantThemeResult.themeType.toString(),
      }
      return (
        <Button
          semantic={'primary'}
          variant={ButtonVariants.outline}
          icon={this.props.defaultTheme.isLight() ? 'moon' : ['regular', 'sun']}
          size={'md'}
          label={this.props.t(`${this.BASE_TRAD}.actions.${this.props.defaultTheme.themeType.toString()}`)}
          onClick={() => {
            this.props.changeTheme(variantThemeToApply.themeName)
          }}
        />
      )
    }

    return <></>
  }

  private renderUserDropdown = () => {
    return (
      <DropdownButton
        actions={this.getUserProfileLinks(this.props.routes)}
        type="submit"
        semantic="brand"
        icon={'user-circle'}
        label={this.props.user?.displayName || ''}
      />
    )
  }

  public render() {
    if (this.props.mobile) {
      return null
    }
    let showBannerTestMode = false

    if (API_BASE_URL && (API_BASE_URL.includes('-test') || API_BASE_URL.includes('wip'))) {
      showBannerTestMode = true
    }

    const renderLeftComponents = (): JSX.Element[] => {
      const leftComponents: JSX.Element[] = []

      if (showBannerTestMode) {
        leftComponents.push(
          <Badge kind={'badge'} semantic={'info'} title={'TESTING'} description={`BASE_URL: ${API_BASE_URL}`} />
        )
      }

      return leftComponents
    }

    return (
      <TopBar
        logo={{ path: `/${this.props.logo}` }}
        leftComponents={renderLeftComponents()}
        actions={
          (this.props.loginSuccess && (
            <>
              {this.renderLightDarkButton()}
              {this.renderThemeDropdownButton()}
              {this.renderChangeLanguageButton()}
              {this.renderUserDropdown()}
            </>
          )) ||
          null
        }
      />
    )
  }
}

const mapStateToProps = (state: any): TopBarStateProps => ({
  loginSuccess: state.auth.loggedIn,
  mobile: state.auth.mobile,
  user: state.auth.user,
  defaultTheme: new ThemeImpl(state.core ? state.core.brandingManager.theme : {}),
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  logout: () => dispatch(logoutUser()),
  changeTheme: clientSlug => dispatch(changeTheme(clientSlug)),
  changeAssets: clientSlug => dispatch(changeAssets(clientSlug)),
})

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopBarCore)))
