import FetchError, { ErrorResponse } from './FetchError'
import { popupNotification } from '../notifications'
import { FetchWrapper as IFetchWrapper } from '@mv-submodules/inplant-components-fe/types/fetchWrapperInterface'
import { showNotification } from '@mv-submodules/inplant-components-fe/ui/components/MVNotification/Notification'
import { HealthChecker } from '../../types/healthChecker'

export default class FetchWrapper implements IFetchWrapper {
  private static instances: Map<string, FetchWrapper> = new Map()
  private KEY_SHOW_SUCCESS_UPDATE = 'show-new-fe-version-installed'
  private baseURL: string
  private i18n: any
  private healthChecker: HealthChecker = new HealthChecker()
  /**
   * FetchWrapper constructor
   * @param {string} baseURL
   * @param {any} i18n
   */
  private constructor(baseURL: string, i18n: any) {
    this.showSuccessUpdateFrontend()
    this.baseURL = baseURL
    this.i18n = i18n
  }

  /**
   * Gets an instance of a FetchWrapper class or creates one
   * @param {string} id
   * @returns {FetchWrapper}
   */
  public static getInstance(id: string): any {
    // return this.instances.get(id) || this.configInstance(id)
    return this.instances.get(id) ? this.instances.get(id) : new Error()
  }

  /**
   * Configure and return a new instance of a FetchWrapper class
   * @param {string} id
   * @param {string} baseURL
   * @param {boolean} overwrite
   * @param {any} i18n
   * @returns {FetchWrapper}
   */
  public static configInstance(
    id: string,
    baseURL: string = 'http://localhost',
    overwrite?: boolean,
    i18n?: any
  ): FetchWrapper {
    let instance = this.instances.get(id)
    if (instance) {
      if (overwrite) {
        instance.baseURL = baseURL
      }
      return instance
    } else {
      instance = new FetchWrapper(baseURL, i18n)
      this.instances.set(id, instance)
      return instance
    }
  }

  /**
   * A simple fetch() wrapper with error handling
   * @param {string} input The resource string
   * @param {RequestInit} init  RequestInit params
   * @param omitAuthorization
   * @param hidePopupNotification
   * @returns {Promise}
   * @throws {FetchError}
   */
  public async request(
    input: string,
    init?: RequestInit,
    omitAuthorization?: boolean,
    hidePopupNotification?: boolean,
    responseHeaders?: string[]
  ): Promise<any> {
    const defaultHeaders: HeadersInit = {}

    // Send JWT token if present
    const requestJWTToken = localStorage.getItem('jwt')
    if (requestJWTToken && omitAuthorization !== true) {
      defaultHeaders.authorization = 'Bearer ' + requestJWTToken
    }

    // Merge default headers with init headers if any
    const requestHeaders = { ...defaultHeaders, ...(init && init.headers ? init.headers : {}) }

    // Merge init params (if any) with headers
    const initParams: RequestInit = { ...(init ? init : {}), headers: requestHeaders }

    try {
      // Try to get a response
      const response = await fetch(this.baseURL + input, initParams)

      this.checkFrontendVersion(response, input)

      // Catch the errors to pass to the caller
      if (!response.ok) {
        if (response.status === 400 || response.status === 406 || response.status === 422 || response.status === 409) {
          const errorResponse: ErrorResponse = await response.json()
          if (!hidePopupNotification) {
            popupNotification({
              title: this.i18n.t('api.errors.error'),
              text: errorResponse.detail || errorResponse.message || errorResponse.title || '',
              type: 'danger',
            })
          }
          throw new FetchError(
            errorResponse.message,
            errorResponse.detail,
            errorResponse.statusCode,
            errorResponse.errors
          )
        } else {
          if (!hidePopupNotification) {
            popupNotification({
              title: this.i18n.t('api.errors.error'),
              text: this.i18n.t(`api.errors.error_${response.status}`),
              type: 'danger',
            })
          }
          throw new FetchError(response.statusText || '', '', response.status || 500)
        }
      }

      // Renew JWT token if present
      const responseJWTToken = response.headers.get('JWT')

      if (responseJWTToken) {
        localStorage.setItem('jwt', responseJWTToken)
      }

      return this.handleReturn(response, response.headers.get('content-type') || undefined, responseHeaders)
    } catch (error) {
      // Pass the rejection to the caller
      throw error
    }
  }

  public async requestMock(
    input: string,
    init?: RequestInit | undefined,
    omitAuthorization?: boolean | undefined,
    hidePopupNotification?: boolean | undefined,
    responseHeaders?: string[] | undefined,
    delay: number = 500,
    randLimit: number = 0.1
  ): Promise<any> {
    // Merge init params (if any) with headers
    const initParams: RequestInit = { ...(init ? init : {}) }

    const requestMockBaseUrl: string = window.location.origin

    try {
      // Try to get a response
      const response = await fetch(requestMockBaseUrl + '/data' + input + '.json', initParams)

      await new Promise(resolve => setTimeout(resolve, delay))

      const mathRandom = Math.random()
      if (mathRandom < randLimit) {
        throw new FetchError('Error', 'Error', 500)
      }

      return response.json()
    } catch (error) {
      // Pass the rejection to the caller
      throw error
    }
  }

  private async handleReturn(response: Response, contentType?: string, desiredHeaders?: string[]): Promise<any> {
    let value
    // Manage return values based on reponse's content-type
    if (contentType && contentType.indexOf('application/json') !== -1) {
      value = await response.json()
    } else if (contentType && contentType.indexOf('text') !== -1) {
      value = await response.text()
    } else {
      value = await response.blob()
    }
    if (!desiredHeaders) {
      return value
    }
    return {
      result: value,
      ...desiredHeaders.reduce((headers: { [k: string]: string | null }, curr) => {
        headers[curr] = response.headers.get(curr)
        return headers
      }, {}),
    }
  }

  private checkFrontendVersion = (response: Response, url: string) => {
    this.showSuccessUpdateFrontend()
    const frontEndVersion = response.headers.get('frontend-version')
    if (frontEndVersion) {
      this.healthChecker.update(frontEndVersion)
    }
  }

  private showSuccessUpdateFrontend = async () => {
    if (JSON.parse(localStorage.getItem(this.KEY_SHOW_SUCCESS_UPDATE) || JSON.stringify(false)) && this.i18n) {
      showNotification({
        uniqueNotification: true,
        onNewUniqueNotification: 'maintainPrevious',
        slug: 'new-fe-version-installed',
        type: 'success',
        message: this.i18n.t('api.newVersionInstalled.message'),
      })
      localStorage.setItem(this.KEY_SHOW_SUCCESS_UPDATE, JSON.stringify(false))
    }
  }
}
