import React, { useEffect } from 'react'
import { Redirect } from 'react-router'

import { BrandingManagerStore } from '@mv-submodules/inplant-core-fe/redux/reducers/branding'
import { useAppSelector } from '@mv-submodules/inplant-core-fe/redux/reducers'

import CenterLoader from '../../../../../inplant-components-fe/ui/components/MVLoaders/CenterLoader'

interface StateProps {
  fetching: boolean
  error: Error | null
  loggedIn: boolean
  useNewLogin: boolean
  themeDefault: BrandingManagerStore
}

interface Props {
  successPath: string
  image?: string
}

const classNameLogin = 'mv-login'
const redirectPath =
  process.env.REACT_APP_LOGIN_REDIRECT ?? ((window as never) as { LOGIN_REDIRECT?: string }).LOGIN_REDIRECT

const Login = (props: Props) => {
  const auth: StateProps = useAppSelector((state: any) => state.auth)

  useEffect(() => {
    if (!auth.loggedIn && redirectPath) {
      // [26730](https://pm.mvlabs.it/issues/26730)
      window.open(redirectPath, '_self')
    }
  }, [auth.loggedIn])

  if (auth.loggedIn) {
    return <Redirect to={props.successPath} />
  }

  return (
    <div className={`${classNameLogin}-container`}>
      <CenterLoader />
    </div>
  )
}

export default Login
